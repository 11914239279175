import React from 'react';
import Header from './header';

import { URL, URL_SOCKET, TURN_1, TURN_2, TURN_3, TURN_4 } from '../variables';
import { connect } from 'react-redux';
import { mudarUser, logout, mudarProcesso, mudarSocket, mudarSalaScreen, mudarChatOpen, changeShowCall, changeConnect } from '../actions/AppActions';
import { Redirect, Link } from 'react-router-dom';
import io from 'socket.io-client';

import moment from 'moment-timezone';
import Chat from './Chat';
import ReportAction from './ReportAction';
import ChatIndividual from './ChatIndividual';
import ReactTooltip from 'react-tooltip';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import HeaderSala from './HeaderSala';
import { getRoom, getRooms } from '../services/requests';
import SolicitacaoModal from './SolicitacaoModal';
import SweetAlert from 'react-bootstrap-sweetalert';
import MeetingRoom from "./Meet";
import ListStudents from './ListStudents';

class Sala extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            msg: '',
            loading_screen: true,
            redirect: false,
            path: '/',
            salas: [],
            sala_select: {},
            number_online: [],
            alunos_m: [],
            comunicados: [],
            salas_ids: [],
            full_screen_open: false,
            aluno_fullscreen: -1,
            candidatos: [],
            width_candidato_box: 0,
            height_candidato_box: 0,
            alunoChat_select: {},
            candidato_selectAcao: {},
            show_modal_action: false,
            show_modal_chat_geral: false,
            show_modal_request:false,
            times_reconects: {},
            loading_sala: false,
            loading_alter_video: {},
            video_blink: {},
            video_blink_movement: {},
            number_line:[],
            requestSelect:null,
            message_warning:null,
            loadings_pause:{},
            show_chat_meet:false,
            list_open:false

        }
        this.fullscreen_ref = React.createRef();
        this.audio_context_peers = {}
        this.list_peers = {};
        this.configuration = {
            iceServers: [
                { urls: "stun:stun.1.google.com:19302" },
                {
                    'urls': 'turn:turnserver.ontest.com.br:3478?transport=udp',
                    'credential': 'Coconuts69341228#',
                    'username': 'onteste_projetos'
                },
                // {
                //     'urls': 'turn:' + TURN_1 + ':3478?transport=udp',
                //     'credential': 'Coconuts69341228#',
                //     'username': 'onteste_projetos'
                // },
                // {
                //     'urls': 'turn:' + TURN_2 + ':3478?transport=udp',
                //     'credential': 'Coconuts69341228#',
                //     'username': 'onteste_projetos'
                // },
                // {
                //     'urls': 'turn:' + TURN_3 + ':3478?transport=udp',
                //     'credential': 'Coconuts69341228#',
                //     'username': 'onteste_projetos'
                // },
                // {
                //     'urls': 'turn:' + TURN_4 + ':3478?transport=udp',
                //     'credential': 'Coconuts69341228#',
                //     'username': 'onteste_projetos'
                // }

            ],
            sdpSemantics: 'unified-plan'
        };
        this.unmont = false;
        this.good_quality_student={};
        this.timeout_request={}
        // this.videoRef=null;
    }
    handle_offer_sdp(offer) {
        let sdp = offer.sdp.split('\r\n');//convert to an concatenable array
        let new_sdp = '';
        let position = null;
        sdp = sdp.slice(0, -1); //remove the last comma ','
        for (let i = 0; i < sdp.length; i++) {//look if exists already a b=AS:XXX line
            if (sdp[i].match(/b=AS:/)) {
                position = i; //mark the position
            }
        }
        if (position) {
            sdp.splice(position, 1);//remove if exists
        }
        for (let i = 0; i < sdp.length; i++) {
            if (sdp[i].match(/m=video/)) {//modify and add the new lines for video
                new_sdp += sdp[i] + '\r\n' + 'b=AS:' + '24' + '\r\n';
            }
            else {
                if (sdp[i].match(/m=audio/)) { //modify and add the new lines for audio
                    new_sdp += sdp[i] + '\r\n' + 'b=AS:' + 48 + '\r\n';
                }
                else {
                    new_sdp += sdp[i] + '\r\n';
                }
            }
        }
        return new_sdp; //return the new sdp
    }
    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }


    componentDidMount() {
        // document.getElementById('header_page').classList.add('mouse_come_here');
        // this.mouseEvent = (function (e) {
        //     if (e.clientY < 70 && this.props.chat_open == false) {
        //         document.getElementById('header_page').classList.add('visible');
        //         document.getElementById('header_page').classList.remove('mouse_come_here');
        //     }
        //     else {
        //         document.getElementById('header_page').classList.remove('visible');
        //         document.getElementById('header_page').classList.add('mouse_come_here');
        //     }
        // }).bind(this);
        // window.addEventListener('mousemove', this.mouseEvent);

        let width_candidato_box = window.innerWidth / 6;
        let height_candidato_box = (window.innerHeight - 0) / 4;
        this.setState({ width_candidato_box, height_candidato_box });
        this.resizeEvent = (function (event) {
            console.log(window.innerWidth);
            console.log(window.innerHeight);
            let width_candidato_box = window.innerWidth / 6;
            if (this.props.chat_open != -1 || this.state.list_open==true) {
                width_candidato_box = (window.innerWidth - 300) / 6;
            }
            let height_candidato_box = (window.innerHeight - 0) / 4;
            this.setState({ width_candidato_box, height_candidato_box });

            console.log(event);
        }).bind(this);
        window.addEventListener('resize', this.resizeEvent, true);

        this.props.mudarSalaScreen(true);
        this.get_salas(this.props.match.params.id);

    }

    componentDidUpdate(props, state) {
        if (props.chat_open != this.props.chat_open || this.state.list_open!=state.list_open) {
            let width_candidato_box = window.innerWidth / 6;
            if (this.props.chat_open != -1 || this.state.list_open==true) {
                width_candidato_box = (window.innerWidth - 300) / 6;
            }
            let height_candidato_box = (window.innerHeight - 0) / 4;
            this.setState({ width_candidato_box, height_candidato_box });
        }


        if (this.state.sala_select.alunos != undefined && state.sala_select.alunos != undefined) {
            console.log('entrou');
            let actual_ids = Object.values(this.state.sala_select.alunos).sort(this.sort_by_online.bind(this)).filter((item, id) => this.state.number_online.includes(item.id.toString())).map((item, id) => item.id);
            let last_ids = Object.values(state.sala_select.alunos).sort(this.sort_by_online.bind(this)).filter((item, id) => state.number_online.includes(item.id.toString())).map((item, id) => item.id);
            let arrays_equal = true;
            // console.log(actual_ids)
            // console.log(last_ids)
            if (actual_ids.length != last_ids.length) {
                arrays_equal = false;
            }
            else {
                for (let i = 0; i < actual_ids.length; i++) {
                    if (actual_ids[i] != last_ids[i]) {
                        arrays_equal = false;
                        break
                    }
                }
            }
            if (arrays_equal == false) {
                // console.log('Diferente');
                Object.values(this.state.sala_select.alunos).map((item, id) => {
                    try {

                        if (document.getElementById(item.id) != null) {
                            document.getElementById(item.id).srcObject = item.stream
                            if (!this.state.number_online.includes(item.id.toString())) {
                                if (this.state.sala_select.alunos[item.id].bar1 != undefined && this.state.sala_select.alunos[item.id].bar1.current != null) {
                                    this.state.sala_select.alunos[item.id].bar1.current.hidden = true;
                                }
                                if (this.state.sala_select.alunos[item.id].bar2 != undefined && this.state.sala_select.alunos[item.id].bar2.current != null) {
                                    this.state.sala_select.alunos[item.id].bar2.current.hidden = true;
                                }
                                if (this.state.sala_select.alunos[item.id].bar3 != undefined && this.state.sala_select.alunos[item.id].bar3.current != null) {
                                    this.state.sala_select.alunos[item.id].bar3.current.hidden = true;
                                }
                            }
                            else if (this.audio_context_peers[item.id] != undefined) {
                                if (this.audio_context_peers[item.id].isInTime != true) {
                                    if (this.state.sala_select.alunos[item.id].bar1 != undefined && this.state.sala_select.alunos[item.id].bar1.current != null) {
                                        this.state.sala_select.alunos[item.id].bar1.current.hidden = true;
                                    }
                                    if (this.state.sala_select.alunos[item.id].bar2 != undefined && this.state.sala_select.alunos[item.id].bar2.current != null) {
                                        this.state.sala_select.alunos[item.id].bar2.current.hidden = true;
                                    }
                                    if (this.state.sala_select.alunos[item.id].bar3 != undefined && this.state.sala_select.alunos[item.id].bar3.current != null) {
                                        this.state.sala_select.alunos[item.id].bar3.current.hidden = true;
                                    }
                                }
                                else {
                                    if (this.state.sala_select.alunos[item.id].bar1 != undefined && this.state.sala_select.alunos[item.id].bar1.current != null) {
                                        this.state.sala_select.alunos[item.id].bar1.current.hidden = false;
                                    }
                                    if (this.state.sala_select.alunos[item.id].bar2 != undefined && this.state.sala_select.alunos[item.id].bar2.current != null) {
                                        this.state.sala_select.alunos[item.id].bar2.current.hidden = false;
                                    }
                                    if (this.state.sala_select.alunos[item.id].bar3 != undefined && this.state.sala_select.alunos[item.id].bar3.current != null) {
                                        this.state.sala_select.alunos[item.id].bar3.current.hidden = false;
                                    }
                                }
                            }
                            else {
                                if (this.state.sala_select.alunos[item.id].bar1 != undefined && this.state.sala_select.alunos[item.id].bar1.current != null) {
                                    this.state.sala_select.alunos[item.id].bar1.current.hidden = true;
                                }
                                if (this.state.sala_select.alunos[item.id].bar2 != undefined && this.state.sala_select.alunos[item.id].bar2.current != null) {
                                    this.state.sala_select.alunos[item.id].bar2.current.hidden = true;
                                }
                                if (this.state.sala_select.alunos[item.id].bar3 != undefined && this.state.sala_select.alunos[item.id].bar3.current != null) {
                                    this.state.sala_select.alunos[item.id].bar3.current.hidden = true;
                                }
                            }
                        }
                        else {
                            if (this.state.sala_select.alunos[item.id].bar1 != undefined && this.state.sala_select.alunos[item.id].bar1.current != null) {
                                this.state.sala_select.alunos[item.id].bar1.current.hidden = true;
                            }
                            if (this.state.sala_select.alunos[item.id].bar2 != undefined && this.state.sala_select.alunos[item.id].bar2.current != null) {
                                this.state.sala_select.alunos[item.id].bar2.current.hidden = true;
                            }
                            if (this.state.sala_select.alunos[item.id].bar3 != undefined && this.state.sala_select.alunos[item.id].bar3.current != null) {
                                this.state.sala_select.alunos[item.id].bar3.current.hidden = true;
                            }
                        }
                    }
                    catch (e) {
                        console.log(e);
                    }
                })
            }

        }
    }

    componentWillUnmount() {
        // window.removeEventListener('mousemove', this.mouseEvent)
        window.removeEventListener('resize', this.resizeEvent, true);
        this.props.mudarSalaScreen(false);

        clearTimeout(this.logs_timeout);
        clearTimeout(this.timeout_numbers);
        if (this.socket != undefined) {
            this.socket.close();
        }
        this.props.mudarSocket(null);
        this.unmont = true;

    }

    async get_salas(id) {
        this.setState({ loading_screen: true });
        getRooms(id,this.props.token).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    // this.props.mudarLoadingHome(false);
                    setTimeout(() => {
                        this.get_salas(this.props.match.params.id);
                    }, 5000);
                }
                else if (this.unmont == false) {
                    this.props.mudarProcesso(resp.processo);
                    let salas_ids = [];
                    let candidatos = [];
                    let comunicados = [];
                    for (let i = 0; i < resp.comunicados.length; i++) {
                        resp.comunicados[i].send = true;
                        comunicados.push(resp.comunicados[i]);
                    }
                    if (resp.salas.length == 0) {
                        this.setState({ redirect: true });
                        return;
                    }
                    for (let i = 0; i < resp.salas.length; i++) {
                        let alunos = {};
                        salas_ids.push(resp.salas[i].id);
                        // let alunos_lista=[];
                        for (let j = 0; j < resp.salas[i].alunos.length; j++) {
                            candidatos.push({ name: resp.salas[i].alunos[j].name + ' - Sala ' + resp.salas[i].numero, id: resp.salas[i].alunos[j].id, sala_id: resp.salas[i].id });
                            alunos[resp.salas[i].alunos[j].id] = resp.salas[i].alunos[j];
                            alunos[resp.salas[i].alunos[j].id].videoRef = React.createRef();
                            alunos[resp.salas[i].alunos[j].id].bar1 = React.createRef();
                            alunos[resp.salas[i].alunos[j].id].bar2 = React.createRef();
                            alunos[resp.salas[i].alunos[j].id].bar3 = React.createRef();
                            alunos[resp.salas[i].alunos[j].id].desconectado = true;
                        }
                        resp.salas[i].alunos = alunos;
                    }
                    candidatos = candidatos.sort(function (a, b) { return a.name - b.name });
                    this.setState({ candidatos: candidatos, loading_screen: false, salas_ids, comunicados: comunicados, salas: resp.salas, sala_select: { ...resp.salas[0] }, alunos_m: resp.alunos },()=>{
                        clearTimeout(this.timeout_numbers);
                        this.get_numbers();
                    });
                    this.video_init();
                    this.logs_timeout = setTimeout(() => {
                        this.salvar_log();
                    }, 30000);
                }




            } catch (err) {
                console.log(err);
                // this.props.mudarLoadingHome(false);
                setTimeout(() => {
                    this.get_salas(this.props.match.params.id);
                }, 5000);

            }

        })
            .catch((err) => {
                console.log(err);
                // this.props.mudarLoadingHome(false);
                setTimeout(() => {
                    this.get_salas(this.props.match.params.id);
                }, 5000);
            });
    }



    async get_sala(id, sala_id, last_sala) {
        this.setState({ loading_sala: true });
        getRoom(id,sala_id,this.props.token).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    // this.props.mudarLoadingHome(false);
                    setTimeout(() => {
                        this.get_sala(this.props.match.params.id, sala_id);
                    }, 5000);
                }
                else if (this.unmont == false) {
                    if (sala_id == this.state.sala_select.id) {
                        this.props.mudarProcesso(resp.processo);
                        let salas_ids = [];
                        let candidatos = [];
                        let comunicados = [];
                        for (let i = 0; i < resp.comunicados.length; i++) {
                            resp.comunicados[i].send = true;
                            comunicados.push(resp.comunicados[i]);
                        }
                        if (resp.salas.length == 0) {
                            this.setState({ redirect: true });
                            return;
                        }
                        for (let i = 0; i < resp.salas.length; i++) {
                            let alunos = {};
                            salas_ids.push(resp.salas[i].id);
                            // let alunos_lista=[];
                            for (let j = 0; j < resp.salas[i].alunos.length; j++) {
                                candidatos.push({ name: resp.salas[i].alunos[j].name + ' - Sala ' + resp.salas[i].numero, id: resp.salas[i].alunos[j].id, sala_id: resp.salas[i].id });
                                alunos[resp.salas[i].alunos[j].id] = resp.salas[i].alunos[j];
                                alunos[resp.salas[i].alunos[j].id].videoRef = React.createRef();
                                alunos[resp.salas[i].alunos[j].id].bar1 = React.createRef();
                                alunos[resp.salas[i].alunos[j].id].bar2 = React.createRef();
                                alunos[resp.salas[i].alunos[j].id].bar3 = React.createRef();
                                alunos[resp.salas[i].alunos[j].id].desconectado = true;
                            }
                            resp.salas[i].alunos = alunos;
                        }
                        this.setState({ alunoChat_select: {}, loading_sala: false, sala_select: { ...resp.salas[0] }, alunos_m: resp.alunos }, () => {
                            console.log('teste');
                            if (sala_id == this.state.sala_select.id) {
                                clearTimeout(this.timeout_numbers);
                                this.get_numbers();
                                // console.log({ name: 'Admin',  sala_id: this.state.sala_select, last_sala, faculdade_id: this.props.faculdade.id, admin_id: this.admin_id })
                                this.socket.emit('change_room', { name: 'Admin', sala_id: this.state.sala_select.id, last_sala, faculdade_id: this.props.faculdade.id, admin_id: this.admin_id });

                            }

                        });
                    }
                }




            } catch (err) {
                console.log(err);
                // this.props.mudarLoadingHome(false);
                setTimeout(() => {
                    this.get_sala(this.props.match.params.id, sala_id);
                }, 5000);

            }

        })
            .catch((err) => {
                console.log(err);
                // this.props.mudarLoadingHome(false);
                setTimeout(() => {
                    this.get_sala(this.props.match.params.id, sala_id);
                }, 5000);
            });
    }

    sort_by_online(a, b) {
        let number_online = [...this.state.number_online];
        // console.log(a.id+' '+b.id)
        // console.log(number_online.includes(a.id.toString()))
        // console.log(number_online.includes(b.id.toString()))
        // console.log(number_online.includes(a.id.toString()) < number_online.includes(b.id.toString()))
        // console.log(number_online.includes(b.id.toString()) > number_online.includes(a.id.toString()))
        // console.log(number_online)


        if (number_online.includes(a.id.toString()) > number_online.includes(b.id.toString())) {
            return -1;
        }
        if (number_online.includes(b.id.toString()) < number_online.includes(a.id.toString())) {
            return 1;
        }
        return 0;
    }

    draw_peer(id,uuid) {
        let user_id = id;
        if(this.audio_context_peers[user_id]['id']!=uuid){
            return;
        }
        if(this.audio_context_peers[user_id]['context'].state!='running'){
            this.audio_context_peers[user_id]['context'].resume();
        }
        
        if (
            this.state.sala_select.alunos[id]
        ) {

            let bar1 = this.state.sala_select.alunos[id].bar1.current;
            let bar2 = this.state.sala_select.alunos[id].bar2.current;
            let bar3 = this.state.sala_select.alunos[id].bar3.current;
            // console.log(this.state.sala_select.alunos[id])
            // let bar_peer = document.getElementById('indicator'+id);
            // let audio_elem = document.getElementById('audio' + id);

            if (bar1 == null || bar2 == null || bar3 == null || !this.state.number_online.includes(id.toString())) {
                // console.log('encerrou ' + id)
                clearTimeout(this.audio_context_peers[id].timeout);
                if (bar1 != null) {
                    // console.log('hidden')
                    // bar1.hidden = true;
                    // bar2.hidden = true;
                    // bar3.hidden = true;
                    if (this.state.sala_select.alunos[id].bar1 != undefined && this.state.sala_select.alunos[id].bar1.current != null) {
                        this.state.sala_select.alunos[id].bar1.current.hidden = true;
                    }
                    if (this.state.sala_select.alunos[id].bar2 != undefined && this.state.sala_select.alunos[id].bar2.current != null) {
                        this.state.sala_select.alunos[id].bar2.current.hidden = true;
                    }
                    if (this.state.sala_select.alunos[id].bar3 != undefined && this.state.sala_select.alunos[id].bar3.current != null) {
                        this.state.sala_select.alunos[id].bar3.current.hidden = true;
                    }
                }

                return 0;
            }
            // var drawVisual = requestAnimationFrame(()=>draw_peer(id));
            this.audio_context_peers[id]['analyser'].getByteTimeDomainData(this.audio_context_peers[id]['dataArray']);

            var sliceWidth = 300 * 1.0 / this.audio_context_peers[id]['bufferLength'];
            var x = 0;
            let max = 0;

            for (var i = 0; i < this.audio_context_peers[id]['bufferLength']; i++) {
                var v = this.audio_context_peers[id]['dataArray'][i] / 128.0;
                if (((v - 0.93) * 100) > 10) {
                    bar1.hidden = false;
                    bar2.hidden = false;
                    bar3.hidden = false;
                    this.audio_context_peers[id].isInTime = true;
                    clearTimeout(this.audio_context_peers[id].timeout);
                    this.audio_context_peers[id].timeout = setTimeout(() => {
                        this.audio_context_peers[id].isInTime = false;
                        let bar1 = this.state.sala_select.alunos[id]?.bar1?.current;
                        let bar2 = this.state.sala_select.alunos[id]?.bar2?.current;
                        let bar3 = this.state.sala_select.alunos[id]?.bar3?.current;
                        if (bar1 != null) {
                            bar1.hidden = true;
                            bar2.hidden = true;
                            bar3.hidden = true;
                        }
                    }, 10000);
                }
                // else{
                //     bar1[0].hidden=true;
                //     bar2[0].hidden=true;
                //     bar3[0].hidden=true;
                // }
                // if((v-1)>0)
                //     bar_peer.style.clipPath='circle(40%)';
                // else
                //     bar_peer.style.clipPath='circle(30%)';


                if (bar1 != null && bar2 != null && bar3 != null) {
                    // for (let j = 0; j < bar1.length; j++) {
                    //     bar1[j].style.height = bar2[0].style.height;
                    // }
                    bar1.style.height = bar2.style.height;
                    bar2.style.height = bar3.style.height;
                    if (((v - 0.93) * 100) > 15) {
                        bar3.style.height = '15px';
                    }
                    else {
                        bar3.style.height = ((v - 0.93) * 100) + 'px';
                    }

                    if (((v - 0.93) * 100) > max) {
                        max = ((v - 0.93) * 100);
                    }
                }

            }
            setTimeout(() => {
                this.draw_peer(id,uuid)
            }, 300);
        }


    };


    salvar_log() {
        fetch(`${URL}api/fiscal/salvar_log?screen=sala&sala_numero=${this.state.sala_select.numero}&sala_id=${this.state.sala_select.id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else {
                    if (this.unmont == false) {
                        this.logs_timeout = setTimeout(() => {
                            this.salvar_log();
                        }, 30000);
                    }
                }
            } catch (err) {
                console.log(err);
                if (this.unmont == false) {
                    this.logs_timeout = setTimeout(() => {
                        this.salvar_log();
                    }, 30000);
                }

            }

        })
            .catch((err) => {
                console.log(err);
                if (this.unmont == false) {
                    this.logs_timeout = setTimeout(() => {
                        this.salvar_log();
                    }, 30000);
                }
            });
    }

    onIceCandidate(peer, evt, aluno_id) {
        if (evt.candidate) {
            this.socket.emit("candidate_to_aluno", JSON.stringify({ "candidate": evt.candidate, admin_id: this.admin_id, faculdade_id: this.props.faculdade.id, aluno_id: aluno_id }));
        }
    }

    video_init() {
        this.admin_id = this.uuidv4();
        this.socket = io.connect(URL_SOCKET, { "transports": ['websocket'], query: { token: this.props.token } });
        this.props.mudarSocket(this.socket);

        // socket.emit("join", {name:'Admin',sala_id:sala_id,faculdade_id:faculdade_id});
        this.socket.on("connect", function (client) {
            this.props.changeConnect(true);

            // this.socket.emit('join_stream_admin', { sala_id: this.state.sala_select.id, admin_id: this.admin_id, faculdade_id: this.props.faculdade.id })
            this.socket.emit("join", { name: 'Admin', salas_ids: this.state.salas_ids, sala_id: this.state.sala_select.id, faculdade_id: this.props.faculdade.id, admin_id: this.admin_id });

        }.bind(this));

        this.socket.on("candidate", function (msg) {
            // console.log("candidate received");
            if (this.list_peers[JSON.parse(msg).aluno_id] != undefined && this.list_peers[JSON.parse(msg).aluno_id].connectionState != 'closed') {
                this.list_peers[JSON.parse(msg).aluno_id].addIceCandidate(new RTCIceCandidate(JSON.parse(msg).candidate));
            }

        }.bind(this));

        this.socket.on("sdp" + this.admin_id, function (msg) {
            console.log("sdp received");
            let id = JSON.parse(msg).aluno_id;
            if (this.list_peers[id] != undefined) {
                this.list_peers[id].close();
            }
            this.list_peers[id] = new RTCPeerConnection(this.configuration);

            this.list_peers[id].onicecandidate = function (evt) {
                if (!evt.candidate) return;
                // console.log("onicecandidate called");
                this.onIceCandidate(this.list_peers[id], evt, id);
            }.bind(this);

            this.list_peers[id].onaddstream = function (evt) {
                console.log("onaddstream called");
                if (this.state.sala_select.alunos[id] != undefined) {

                    this.state.sala_select.alunos[id].videoRef.current.srcObject = evt.stream;
                    this.state.sala_select.alunos[id].stream = evt.stream;
                    let user_id = id;
                    this.audio_context_peers[user_id] = {}
                    this.audio_context_peers[user_id]['context'] = new AudioContext();
                    this.audio_context_peers[user_id]['analyser'] = this.audio_context_peers[user_id]['context'].createAnalyser();
                    this.audio_context_peers[user_id]['frequencyData'] = null;
                    this.audio_context_peers[user_id]['source'] = this.audio_context_peers[user_id]['context'].createMediaStreamSource(this.state.sala_select.alunos[id].stream);
                    this.audio_context_peers[user_id]['source'].connect(this.audio_context_peers[user_id]['analyser']);
                    this.audio_context_peers[user_id]['gainNode'] = this.audio_context_peers[user_id]['context'].createGain();
                    this.audio_context_peers[user_id]['gainNode'].gain.value = 0.1; // setting it to 10%
                    this.audio_context_peers[user_id]['gainNode'].connect(this.audio_context_peers[user_id]['context'].destination);
                    // analyser.connect(context.destination);

                    this.audio_context_peers[user_id]['context'].resume()
                    this.audio_context_peers[user_id]['analyser'].fftSize = 32;
                    this.audio_context_peers[user_id]['bufferLength'] = this.audio_context_peers[user_id]['analyser'].frequencyBinCount;
                    this.audio_context_peers[user_id]['dataArray'] = new Uint8Array(this.audio_context_peers[user_id]['bufferLength']);
                    this.audio_context_peers[user_id]['analyser'].getByteTimeDomainData(this.audio_context_peers[user_id]['dataArray']);
                    this.audio_context_peers[user_id].id=this.uuidv4()
                    // this.audio.srcObject = evt.stream
                    this.draw_peer(user_id,this.audio_context_peers[user_id].id);

                    console.log('full_screen_added1')
                    // console.log(this.state.aluno_fullscreen,Object.values(this.state.sala_select.alunos)[this.state.aluno_fullscreen]?.id,id)
                    if (this.state.aluno_fullscreen != -1 && this.state.aluno_fullscreen == id) {
                        console.log('full_screen_added2')
                        console.log(evt.stream)
                        this.fullscreen_ref.current.srcObject = evt.stream;
                    }
                }
                // document.getElementById("video_tag" + JSON.parse(msg).aluno_id).srcObject = evt.stream;
            }.bind(this);
            // list_peers[id].oniceconnectionstatechange = function() {
            //     console.log(list_peers[id].iceConnectionState);
            // }
            // list_peers[id].onconnectionstatechange = function() {
            //     console.log(list_peers[id].connectionState);
            // }   
            var sessionDesc = new RTCSessionDescription(JSON.parse(msg).sdp);
            this.list_peers[id].setRemoteDescription(sessionDesc);
            this.list_peers[id].createAnswer().then(function (sdp) {
                if(this.good_quality_student[id]!=true){
                    sdp.sdp = this.handle_offer_sdp(sdp)
                }
                else{
                    console.log('NAO ENTROU SDP')
                }
                console.log('anwser sdp');
                this.list_peers[id].setLocalDescription(new RTCSessionDescription(sdp));
                this.socket.emit("answer", JSON.stringify({ "sdp": sdp, admin_id: this.admin_id, aluno_id: JSON.parse(msg).aluno_id, faculdade_id: this.props.faculdade.id }));
            }.bind(this)).catch(function (e) {
                console.log(e);
            });
        }.bind(this));

        this.socket.on("number_line" + this.props.faculdade.id, function (data) {
            if (data.sala_id == this.state.sala_select.id) {
                this.setState({ number_line:data.number_line?data.number_line:this.state.number_line})

            }
        }.bind(this))

        this.socket.on("aluno_desconectou_" + this.props.faculdade.id, function (data) {
            // console.log(video);
            if (data.aluno_id != undefined) {
                if (data.sala_id == this.state.sala_select.id) {

                    let sala_select = this.state.sala_select;
                    console.log(data);
                    console.log('desconectou');
                    if (this.state.aluno_fullscreen != -1 && this.state.aluno_fullscreen == data.aluno_id) {
                        this.fullscreen_ref.current.srcObject = null;
                    }
                    sala_select.alunos[data.aluno_id].desconectado = true;
                    if (sala_select.alunos[data.aluno_id].videoRef.current != null) {
                        sala_select.alunos[data.aluno_id].videoRef.current.srcObject = null;
                    }
                    let video_blink = { ...this.state.video_blink }
                    let video_blink_movement = { ...this.state.video_blink_movement }

                    video_blink[data.aluno_id] = false;
                    video_blink_movement[data.aluno_id] = false;
                    console.log(video_blink_movement)
                    this.good_quality_student[data.aluno_id]=false;

                    this.setState({ number_online: data.number,number_line:data.number_line?data.number_line:this.state.number_line, sala_select, video_blink ,video_blink_movement})
                }
                else {
                    this.setState({ number_online: data.number })
                }
            }
            else {
                this.setState({ number_online: data.number,number_line:data.number_line?data.number_line:this.state.number_line })
            }
        }.bind(this))

        this.socket.on("aluno_conectou_" + this.props.faculdade.id, function (data) {
            console.log(data);
            // $('#online_number').text(data.number);
            if (data.aluno_id != undefined) {

                if (data.sala_id == this.state.sala_select.id) {
                    let video_blink = { ...this.state.video_blink }
                    let video_blink_movement = { ...this.state.video_blink_movement }

                    let keys = Object.keys(video_blink);
                    for (let i = 0; i < keys.length; i++) {
                        if (!data.number.includes(keys[i].toString())) {
                            video_blink[keys[i]] = false;
                        }
                    }

                    keys = Object.keys(video_blink_movement);
                    for (let i = 0; i < keys.length; i++) {
                        if (!data.number.includes(keys[i].toString())) {
                            video_blink_movement[keys[i]] = false;
                        }
                    }

                    let sala_select = this.state.sala_select;
                    sala_select.alunos[data.aluno_id].desconectado = false
                    this.setState({ number_online: data.number,number_line:data.number_line, sala_select, video_blink,video_blink_movement })
                }
                else {
                    this.setState({ number_online: data.number })
                }

            }
            else {
                this.setState({ number_online: data.number,number_line:data.number_line })
            }
        }.bind(this))

        this.socket.on('disconnect', function () {
            console.log('desconectou socket')
            this.props.changeConnect(false);

        }.bind(this));

        //Send the ICE Candidate to the remote peer
        clearTimeout(this.timeout_numbers);
        this.timeout_numbers = setTimeout(() => {
            this.get_numbers();
        }, 300000);

    }

    get_numbers() {
        if (this.socket != null) {
            this.socket.emit("get_numbers", { faculdade_id: this.props.faculdade.id, admin_id: this.admin_id,sala_id:this.state.sala_select.id });
        }
        clearTimeout(this.timeout_numbers);
        this.timeout_numbers = setTimeout(() => {
            this.get_numbers();
        }, 300000);
    }

    receive_all(uuid, sala_id, aluno_id) {
        fetch(`${URL}api/fiscal/receive_all_fiscal`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                uuid,
                sala_id, aluno_id
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else {

                }
            } catch (err) {
                console.log(err);
            }

        })
            .catch((err) => {
                console.log(err);
            });
    }

    sendReceive(aluno){
        let aluno2 = this.state.alunos_m[aluno.id];
        if (aluno2.mensagens.length > 0) {
            this.props.socket.emit('msg_receive_aluno', { sala_id: aluno2.sala_id, aluno_id: aluno2.id, uuid: aluno2.mensagens[aluno2.mensagens.length - 1].uuid, faculdade_id: this.props.faculdade.id });
            this.receive_all(aluno2.mensagens[aluno2.mensagens.length - 1].uuid, aluno2.sala_id, aluno2.id);
        }
        let alunos = { ...this.state.alunos_m };
        alunos[aluno2.id].counter = 0;
        this.setState({ alunos_m: alunos, alunoChat_select: { ...alunos[aluno2.id] } });
    }

    send_message(msg) {
        let uid_msg = this.uuidv4();

        if (msg != null && msg != '' && msg.replace(/ /g, '') != '') {
            this.props.socket.emit("send_msg", { processo_id:this.props.processo.id,msg: msg, sala_id: this.state.alunoChat_select.sala_id, aluno_id: this.state.alunoChat_select.id, nome_chat: this.props.user.name, id_msg: uid_msg, admin_id: this.admin_id, faculdade_id: this.props.faculdade.id, id_adm: this.props.user.id });
            // $(this).val('');
            // $('#body_msg').append(template_send.replace(/%MSG%/g,msg).replace(/%ID%/g,uid_msg));
            // $('#body_msg').scrollTop($('#body_msg')[0].scrollHeight);
            let alunos = { ...this.state.alunos_m };
            // let aluno_select={...this.state.aluno_select};
            // aluno_select.mensagens.push({
            //     sender: { id: this.props.user.id, name: this.props.user.name, }, sender_id: this.props.user.id,
            //     receive_id: this.state.aluno_select.id, sala_id:this.state.aluno_select.sala_id, uuid: uid_msg, visto: false, message: msg
            // });
            alunos[this.state.alunoChat_select.id].mensagens.push({
                sender: { id: this.props.user.id, name: this.props.user.name, }, sender_id: this.props.user.id,
                receive_id: this.state.alunoChat_select.id, sala_id: this.state.alunoChat_select.sala_id, uuid: uid_msg, visto: undefined, message: msg,
                created_at: moment().format('YYYY-MM-DD HH:mm:ss')

            });
            this.setState({alunos_m:alunos});
            return true;
        }
        return false;
    }



    get_messages_from_student(id) {
        console.log('id',id)
        console.log(this.state.alunos_m)
        return [...this.state.alunos_m[id].mensagens];
    }



    render() {
        console.log(this.good_quality_student)
        console.log(this.state.alunos_m)
        return (
            <div>
                {/* <HeaderSala showChatGeral={(e) => {
                    this.setState({ show_modal_chat_geral: e });
                }} /> */}

                {this.state.redirect == true && <Redirect to={this.state.path} />}

                {this.state.loading_screen == false && <div className="row">
                    {/* <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>{this.props.faculdade.name}</h4>
                                <p style={{ fontSize: '1rem', color: 'black' }}>Nome do Fiscal: {this.props.user.name}</p>

                                <h4 style={{ color: 'black' }}>Comunicado Geral</h4>


                                <div className="d-flex" style={{ marginBottom: '0.5rem', marginTop: '1rem' }}>
                                    <textarea id="msg_comunicado" placeholder="Escreva um comunicado Geral para enviar aos candidatos" className="form-control" style={{ resize: 'none', height: '100px' }} />
                                    <button className="btn btn-primary" id="enviar_comunicado">
                                        Enviar
                                        </button>
                                </div>

                            </div>
                        </div>
                    </div> */}
                    <div className="col-12">
                        <div className="card" style={{ marginBottom: 0 }}>
                            <div className="card-body" style={{ padding: 0 }}>
                                <div className="row" style={{ padding: '1.25rem' }}>

                                    <div className="col-12" style={{ marginBottom: '1rem' }}>
                                        <div style={{ padding: '1.25rem', position: 'absolute', right: 0 }}>
                                        <button onClick={() => {
                                            if(this.state.loading_sala) return;
                                            this.setState({list_open:!this.state.list_open})
                                            }} className="btn  btn-outline-info btn-circle m-r-5" ><i
                                                className="mdi mdi-group"
                                                style={{ fontSize: '15px',  fontStyle: 'normal', paddingRight: '1rem' }}>
                                                    Lista de Candidatos</i></button>
                                            {(this.props.processo.type_fiscalization == 'all' || this.props.processo.type_fiscalization == 'image') && this.props.processo.chat_type != 'none' && <button onClick={() => {
                                            if(this.state.loading_sala) return;
                                                
                                                this.setState({ alunoChat_select: { id: 'comunicado', name: 'Mensagem para Sala ' + this.state.sala_select.numero, sala_id: this.state.sala_select.id, numero: this.state.sala_select.numero } });
                                                this.props.mudarChatOpen(0);
                                            }} className="btn btn-success btn-outline btn-circle m-r-5" ><i
                                                className="mdi mdi-chat"
                                                style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}>
                                                    Mensagem para sala</i></button>}
                                            {this.props.user.role == 'fiscal_coordenador' && (this.props.processo.type_fiscalization == 'all' || this.props.processo.type_fiscalization == 'image') && this.props.processo.chat_type != 'none' && <button onClick={() => {
                                                this.setState({ alunoChat_select: { id: 'comunicado', name: 'Comunicado Geral' } });
                                                this.props.mudarChatOpen(0);
                                            }} className="btn btn-info btn-outline btn-circle m-r-5" ><i
                                                className="mdi mdi-chat"
                                                style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}>
                                                    Comunicado geral</i></button>}

                                            {/* {(this.props.processo.type_fiscalization == 'all' || this.props.processo.type_fiscalization == 'image') && this.props.processo.chat_type != 'none' && <button onClick={() => {
                                                this.setState({ show_modal_chat_geral: true });
                                            }} className="btn btn-success btn-outline btn-circle m-r-5" ><i
                                                className="mdi mdi-chat"
                                                style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}>
                                                    Chat Geral</i></button>} */}
                                            <Link onClick={() => {
                                                let keys = Object.keys(this.list_peers);
                                                for (let i = 0; i < keys.length; i++) {
                                                    this.list_peers[keys[i]].close();
                                                }
                                                for (let i = 0; i < Object.keys(this.state.sala_select.alunos).length; i++) {
                                                    if (this.state.sala_select.alunos[Object.keys(this.state.sala_select.alunos)[i]].videoRef.current != null) {
                                                        this.state.sala_select.alunos[Object.keys(this.state.sala_select.alunos)[i]].desconectado = true;
                                                        this.state.sala_select.alunos[Object.keys(this.state.sala_select.alunos)[i]].videoRef.current.srcObject = null;
                                                    }
                                                }
                                            }} to='/' className="btn btn-secondary btn-outline btn-circle m-r-5" ><i
                                                className="mdi mdi-reply"
                                                style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}>
                                                    Voltar</i></Link>


                                        </div>


                                        {(this.props.processo.type_fiscalization == 'all' || this.props.processo.type_fiscalization == 'image') && <h4 style={{ color: 'black',fontWeight:'bold' }}>Salas</h4>}
                                        {(this.props.processo.type_fiscalization == 'all' || this.props.processo.type_fiscalization == 'image') && <p style={{ color: 'black', whiteSpace: 'pre-line' }}>{this.props.processo.name}
                                        </p>}

                                        {/* {this.props.processo.type_fiscalization != 'all' && <h4 style={{ color: 'black' }}>{this.props.processo.name}</h4>} */}


                                        <div className="m-b-5">
                                            <span style={{ color: 'green', fontSize: 16 }}>Total de candidatos online: <span id="online_number">{this.state.number_online.length}</span></span>
                                        </div>
                                        <div className="m-b-5">
                                            <span style={{ color: 'green', fontSize: 16 }}>Online nesta sala: <span id="online_number">{Object.keys(this.state.sala_select.alunos).filter((item, id) => this.state.number_online.includes(item)).length}</span></span>
                                        </div>

                                        {this.props.processo.interview == true && <div style={{border:'1px dashed #17a2b8',width:'100%',padding:5}}>
                                            <p style={{ color: '#17a2b8', fontSize: 18,textAlign:'center',marginBottom:5 }}>Candidatos na fila de apresentação: <span id="online_number"><b>{this.state.number_line?.length}</b></span>  </p>
                                            {this.state.number_line?.length>0 && <div className='d-flex justify-content-center align-items-center'>
                                            <button onClick={()=>{
                                                this.props.history.push({pathname:'/presentation/'+this.props.processo.id,search:'?sala_id='+this.state.sala_select.id})
                                            }} className='btn btn-info'>Falar com o(s) candidato(s) <i className='mdi mdi-call-made'></i></button>
                                        </div>}

                                        </div>} 


                                    </div>
                                    {this.state.salas.map((item, id) => (

                                        <div key={id} className="col-3" style={{ marginBottom: '0.5rem' }}>


                                            <a onClick={() => {
                                                if (this.state.loading_sala === true) {
                                                    return;
                                                }
                                                // if(item.id!=this.state.sala_select.id){
                                                let keys = Object.keys(this.list_peers);
                                                let last_sala = this.state.sala_select.id;
                                                for (let i = 0; i < keys.length; i++) {
                                                    this.list_peers[keys[i]].close();
                                                }
                                                console.log(item);
                                                for (let i = 0; i < Object.keys(this.state.sala_select.alunos).length; i++) {
                                                    if (this.state.sala_select.alunos[Object.keys(this.state.sala_select.alunos)[i]].videoRef.current != null) {
                                                        this.state.sala_select.alunos[Object.keys(this.state.sala_select.alunos)[i]].desconectado = true;
                                                        this.state.sala_select.alunos[Object.keys(this.state.sala_select.alunos)[i]].videoRef.current.srcObject = null;
                                                    }

                                                }
                                                this.props.mudarChatOpen(-1);
                                                this.good_quality_student={};

                                                this.setState({ sala_select: item, alunoChat_select: {}, video_blink: {},video_blink_movement:{},list_open:false }, () => {
                                                    this.get_sala(this.props.match.params.id, item.id, last_sala);
                                                });
                                                // }
                                            }} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px', border: this.state.sala_select.id == item.id ? '1px solid green' : '1px solid grey' }}>
                                                <span style={{ color: this.state.sala_select.id == item.id ? 'green' : 'grey' }}>Sala {item.numero}</span>
                                                <div >
                                                    {/* <div className="msg_count" style="position: absolute;right:20px;top:10px">
                                        <span>Nova</span>
                                    </div> */}
                                                </div>


                                            </a>

                                        </div>
                                    ))}
                                </div>
                                <hr />

                                {this.state.loading_sala == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div></div>}





                                {this.state.loading_sala == false && (this.props.processo.type_fiscalization == 'all' || this.props.processo.type_fiscalization == 'image') && <div className="row" style={{ margin: 0, marginRight: this.props.chat_open != -1 || this.state.list_open ? 300 : 0 }}>
                                    {Object.values(this.state.sala_select.alunos).sort(this.sort_by_online.bind(this)).map((aluno, id) => (
                                        this.state.alunos_m[aluno.id] != undefined && <div key={id}
                                            // onClick={() => {
                                                
                                            //     this.setState({ full_screen_open: true, aluno_fullscreen: id });
                                            //     this.fullscreen_ref.current.srcObject = aluno.videoRef.current.srcObject;
                                            // }}
                                            className={this.state.video_blink[aluno.id] == true ? 'blink' : ''}
                                            style={{ position: 'relative', padding: 0, flex: "1 0 16%", maxWidth: this.state.width_candidato_box, height: this.state.height_candidato_box, display: 'flex', justifyContent: 'center', marginTop: '0rem', border: '0.5px solid grey' }}>
                                            <div className={"coluna_video"}
                                                style={{ width: '100%', height: '100%', background: 'black' }}>
                                                {!this.state.number_online.includes(aluno.id.toString()) && <div className="desconectado{{$aluno->id}}"
                                                    style={{ height: '100%', width: '100%', background: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute' }}>
                                                    <p style={{ color: 'white', fontSize: '14px' }}>Desconectado...</p>
                                                </div>}
                                                <div style={{ borderRadius: 8, height: '100%', display: 'flex' }} className="align-items-lg-start align-items-md-center">
                                                    <video id={aluno.id} ref={aluno.videoRef} className="video_tag_aluno" autoPlay muted style={{ zIndex: !this.state.number_online.includes(aluno.id.toString()) ? -1 : 'inherit', width: '100%', position: 'absolute', borderRadius: 8 }} ></video>
                                                </div>
                                                {this.state.video_blink_movement[aluno.id] == true && <div className="blink_background" style={{position:'absolute',top:10,left:10}}>
                                                    <p style={{marginBottom:0,fontSize:10,color:'white',marginLeft:4,marginRight:4}}>Movimento</p>
                                                </div>}
                                                {this.state.alunos_m[aluno.id].requests.filter((item)=>item.started!=null && item.pause_end==null).length>0 && <div className="blink_pause" style={{position:'absolute',top:10,right:10}}>
                                                    <p style={{marginBottom:0,fontSize:10,color:'white',marginLeft:4,marginRight:4}}>{moment().diff(moment(this.state.alunos_m[aluno.id].requests.filter((item)=>item.started!=null && item.pause_end==null)[0].started).add('seconds',this.state.alunos_m[aluno.id].requests.filter((item)=>item.started!=null && item.pause_end==null)[0].time),'seconds')>0?'Atraso para voltar': 'Em Pausa'}</p>
                                                </div>}
                                                <div
                                                    style={{ position: 'absolute', top: '10px', right: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 11 }}>
                                                    <div hidden className={"bar1" + aluno.id} ref={aluno.bar1} id="bar1_principal"
                                                        style={{ width: '4px', height: '0px', borderRadius: '100px', border: '0.5px solid black', backgroundColor: 'yellow', marginRight: '1px' }}>
                                                    </div>
                                                    <div hidden className={"bar2" + aluno.id} ref={aluno.bar2} id="bar2_principal"
                                                        style={{ width: '4px', height: '0px', borderRadius: '100px', border: '0.5px solid black', backgroundColor: 'yellow', marginRight: '1px' }}>
                                                    </div>
                                                    <div hidden className={"bar3" + aluno.id} ref={aluno.bar3} id="bar3_principal"
                                                        style={{ width: '4px', height: '0px', borderRadius: '100px', border: '0.5px solid black', backgroundColor: 'yellow', marginRight: '1px' }}>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ position: 'absolute', color: 'white', boxShadow: '0px 1px 3px 0px rgb(60 64 67 / 30%), 0px 4px 8px 3px rgb(60 64 67 / 15%)', borderRadius: 8, background: 'rgba(0,0,0,0.5)', right: 0, left: 0, bottom: 0, height: 'auto', padding: 5 }}>
                                                <p style={{ margin: 0,fontSize:12 }}>{aluno.name}</p>
                                                <div className="row" style={{ margin: 0 }}>
                                                    {this.props.processo.chat_type != 'none' && <div data-tip={"Abrir o chat com o candidato " + aluno.name + ""} className="icon_box" style={{ position: 'relative', marginRight: 2 }} onClick={(e) => {
                                                        // e.stopPropagation();
                                                        if (this.state.loading_sala === true) {
                                                            return;
                                                        }
                                                        
                                                        this.sendReceive(aluno);

                                                        this.props.mudarChatOpen(0);
                                                    }}>
                                                        <ReactTooltip />

                                                        <i style={{ color: 'inherit' }} className="mdi mdi-chat"></i>
                                                        {this.state.alunos_m[aluno.id] != undefined && this.state.alunos_m[aluno.id].counter > 0 && <div style={{
                                                            // padding: '2px',
                                                            // paddingTop: '3px',
                                                            // height:15,
                                                            minWidth: 20,
                                                            background: 'rgb(75, 181, 67)',
                                                            borderRadius: '50px',
                                                            position: 'absolute',
                                                            top: -5,
                                                            textAlign: 'center',
                                                            // paddingBottom: '3px',
                                                            right: -5
                                                        }}>

                                                            <span style={{ color: 'white', textAlign: 'center', fontSize: 11 }}>{this.state.alunos_m[aluno.id].counter}</span>
                                                        </div>}
                                                    </div>}
                                                    {this.props.processo.chat_type != 'none' && <div data-tip={"Visualizar solicitações do candidato " + aluno.name + ""} className="icon_box" style={{ position: 'relative', marginRight: 2 }} onClick={(e) => {
                                                        // e.stopPropagation();
                                                        if (this.state.loading_sala === true) {
                                                            return;
                                                        }
                                                        
                                                        this.setState({  alunoChat_select: { ...this.state.alunos_m[aluno.id] } });
                                                        this.props.mudarChatOpen(1);
                                                    }}>
                                                        <ReactTooltip />

                                                        <i style={{ color: 'inherit' }} className="mdi mdi-pause-circle"></i>
                                                        {this.state.alunos_m[aluno.id] != undefined && (this.state.alunos_m[aluno.id].requests.length > 0 && 
                                                            this.state.alunos_m[aluno.id].requests[this.state.alunos_m[aluno.id].requests.length-1].approved==null
                                                        )  && <div style={{
                                                            // padding: '2px',
                                                            // paddingTop: '3px',
                                                            // height:15,
                                                            minWidth: 20,
                                                            background: '#17a2b8',
                                                            borderRadius: '50px',
                                                            position: 'absolute',
                                                            top: -5,
                                                            textAlign: 'center',
                                                            // paddingBottom: '3px',
                                                            right: -5
                                                        }}>

                                                            <span style={{ color: 'white', textAlign: 'center', fontSize: 11 }}>1</span>
                                                        </div>}
                                                    </div>}

                                                    <div data-tip={"Número de vezes que o candidato " + aluno.name + " saiu da tela"} className="icon_box" style={{ position: 'relative', marginRight: 2 }} >
                                                        <ReactTooltip />
                                                        <span style={{ color: this.state.alunos_m[aluno.id].mensagens.filter((item, id) => item.warning == true && item.message == 'O candidato saiu da página da avaliação').length > 0 ? 'yellow' : 'white', textAlign: 'center', fontSize: 11 }}>{this.state.alunos_m[aluno.id].mensagens.filter((item, id) => item.warning == true && item.message == 'O candidato saiu da página da avaliação').length}</span>

                                                    </div>

                                                    <div
                                                        onClick={() => {
                                                            this.setState({ full_screen_open: true, aluno_fullscreen: aluno.id, alunoChat_select: { ...this.state.alunos_m[aluno.id] } });
                                                            this.fullscreen_ref.current.srcObject = aluno.videoRef.current.srcObject;
                                                        }}
                                                        data-tip={"Ver candidato em tela cheia para ouví-lo"} className="icon_box" style={{ position: 'relative', marginRight: 2 }} >
                                                        <ReactTooltip />
                                                        <i style={{ color: 'inherit' }} className="mdi mdi-fullscreen"></i>

                                                    </div>
                                                   
                                                    {this.props.processo.screen_sharing == true && <div
                                                        onClick={() => {
                                                            // this.setState({ full_screen_open: true, aluno_fullscreen: id, alunoChat_select: { ...this.state.alunos_m[aluno.id] } });
                                                            // this.fullscreen_ref.current.srcObject = aluno.videoRef.current.srcObject;
                                                            if (this.state.loading_alter_video[aluno.id] != true) {
                                                                let loading_alter_video = { ...this.state.loading_alter_video }
                                                                loading_alter_video[aluno.id] = true;
                                                                this.setState({ loading_alter_video })
                                                                this.socket.emit("alter_video", { aluno_id: aluno.id, faculdade_id: this.props.faculdade.id, admin_id: this.admin_id });
                                                                setTimeout(() => {
                                                                    let loading_alter_video = { ...this.state.loading_alter_video }
                                                                    loading_alter_video[aluno.id] = false;;
                                                                    this.setState({ loading_alter_video })
                                                                }, 5000)
                                                            }
                                                        }}
                                                        data-tip={"Alterar o vídeo do candidato para a webcam ou tela compartilhada"} className="icon_box" style={{ position: 'relative', marginRight: 2 }} >
                                                        <ReactTooltip />
                                                        {this.state.loading_alter_video[aluno.id] != true && <i style={{ color: 'inherit' }} className="mdi mdi-monitor-multiple"></i>}
                                                        {this.state.loading_alter_video[aluno.id] == true && <div className="spinner-border text-primary" role="status" style={{ width: '1rem', height: '1rem', margin: 0 }} >
                                                        </div>}

                                                    </div>}

                                                    <div
                                                        onClick={() => {
                                                            this.setState({ candidato_selectAcao: aluno, show_modal_action: true });
                                                            // this.setState({ full_screen_open: true, aluno_fullscreen: id, alunoChat_select: { ...this.state.alunos_m[aluno.id] } });
                                                            // this.fullscreen_ref.current.srcObject = aluno.videoRef.current.srcObject;
                                                        }}
                                                        data-tip={"Reportar ação do candidato"} className="icon_box" style={{ position: 'relative', marginRight: 2 }} >
                                                        <ReactTooltip />
                                                        <i style={{ color: 'inherit' }} className="fas fa-exclamation-circle"></i>

                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: 2 }}>
                                                        {this.state.alunos_m[aluno.id].finish == 'F' && <div data-tip={"Candidato finalizou a avaliação"} style={{ borderRadius: 100, backgroundColor: '#815ae4', height: 25, display: 'flex', justifyContent: 'center', alignItems: 'center', width: 30, height: 30 }}>
                                                            <p style={{ margin: 0, color: 'white', fontSize: 10 }}>F</p>
                                                            <ReactTooltip />
                                                        </div>}
                                                        {this.state.alunos_m[aluno.id].finish == 'I' && <div data-tip={"Candidato não finalizou a avaliação"} style={{ borderRadius: 100, backgroundColor: 'darkgoldenrod', height: 25, display: 'flex', justifyContent: 'center', alignItems: 'center', width: 30, height: 30 }}>
                                                            <p style={{ margin: 0, color: 'white', fontSize: 10 }}>NF</p>
                                                            <ReactTooltip />
                                                        </div>}
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <div onClick={() => {
                                                            if (this.state.times_reconects[aluno.id] == null) {
                                                                let times_reconects = { ...this.state.times_reconects };

                                                                times_reconects[aluno.id] = moment();
                                                                this.setState({ times_reconects });
                                                                console.log('Reconectando');
                                                                this.socket.emit("reconect_user", { aluno_id: aluno.id, faculdade_id: this.props.faculdade.id, admin_id: this.admin_id });

                                                            }
                                                            else {
                                                                if (moment().diff(this.state.times_reconects[aluno.id], 'seconds') > 60) {
                                                                    let times_reconects = { ...this.state.times_reconects };

                                                                    times_reconects[aluno.id] = moment();
                                                                    this.setState({ times_reconects });
                                                                    this.socket.emit("reconect_user", { aluno_id: aluno.id, faculdade_id: this.props.faculdade.id, admin_id: this.admin_id });
                                                                }
                                                                else {
                                                                    console.log(moment().diff(this.state.times_reconects[aluno.id], 'seconds'))
                                                                }

                                                            }
                                                        }} data-tip={"Reconectar com o candidato"} className="icon_box" style={{ position: 'relative' }}>
                                                            <i style={{ color: 'inherit' }} className="mdi mdi-reload"></i>
                                                            <ReactTooltip />
                                                        </div>
                                                    </div>
                                                    {this.state.alunos_m[aluno.id].presence!=null && <div
                                                        onClick={() => {
                                                            this.setState({list_open:!this.state.list_open})

                                                        }}
                                                        data-tip={this.state.alunos_m[aluno.id].presence.presence? "Candidato marcado como presente":"Candidato marcado como ausente"} className="icon_box" style={{ position: 'relative', marginRight: 2,background:this.state.alunos_m[aluno.id].presence.presence?'green':'red' }} >
                                                        <ReactTooltip />
                                                        <i style={{ color: 'inherit' }} className={this.state.alunos_m[aluno.id].presence.presence?"mdi mdi-check": "mdi mdi-close"}></i>

                                                    </div>}


                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>}
                                <div hidden={!this.state.full_screen_open}
                                    style={{ padding: 0, position: 'fixed', top: 0, bottom: 0, left: 0, right: this.props.chat_open != -1 || this.state.list_open ? 300 : 0, zIndex: 1000 }}>
                                    <div className="coluna_video"
                                        style={{ width: '100%', height: '100%', background: 'black' }}>
                                        <div onClick={() => {
                                            console.log('good quality', this.good_quality_student[this.state.alunoChat_select.id])
                                            if(this.good_quality_student[this.state.alunoChat_select.id] && this.props.processo.good_quality==true){
                                                this.good_quality_student[this.state.alunoChat_select.id]=false;
                                                this.socket.emit("good_quality", {good:false, aluno_id: this.state.alunoChat_select.id, faculdade_id: this.props.faculdade.id, admin_id: this.admin_id });
                                            }
                                            this.fullscreen_ref.current.srcObject = null;
                                            this.setState({ aluno_fullscreen: -1, full_screen_open: false });

                                        }} style={{ position: 'absolute', top: '10px', right: '10px', color: 'white', zIndex: 5000 }} className="close"><i className="mdi mdi-close" style={{ fontSize: '1.7rem' }}></i></div>
                                        {this.state.alunoChat_select.id != undefined && !this.state.number_online.includes(this.state.alunoChat_select.id.toString()) && this.state.aluno_fullscreen != -1 && <div className="desconectado{{$aluno->id}}"
                                            style={{ height: '100%', width: '100%', backgroundColor: 'rgba(0,0,0,1)', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute' }}>
                                            <p style={{ color: 'white', fontSize: '1rem', zIndex: 1 }}>Desconectado...</p>
                                        </div>}
                                        <video ref={this.fullscreen_ref} className="video_tag_aluno" autoPlay style={{ height: '100%', width: '100%', position: 'absolute' }} ></video>
                                       {this.state.alunoChat_select.id != undefined && this.state.aluno_fullscreen != -1 && <p onClick={(e)=>{
                                            this.setState({list_open:!this.state.list_open});
                                        }} style={{cursor:'pointer', marginBottom: 0, color: 'black',fontSize:15,position:'absolute',top:10,background:'white',borderRadius:10,padding:2 }}><b>Presença:</b> {this.state.alunos_m[this.state.alunoChat_select.id].presence==null?(<span style={{color:'darkgoldenrod'}}>Não registrado</span>): (this.state.alunos_m[this.state.alunoChat_select.id].presence.presence? <span style={{color:'green'}}>Presente</span>:<span style={{color:'red'}}>Ausente</span>)} <i className='mdi mdi-arrow-right'></i></p>}
                                    </div>
                                    {/* <div onClick={() => {
                                        if (this.state.aluno_fullscreen == 0) {
                                            let indice = Object.values(this.state.sala_select.alunos).length - 1;
                                            this.setState({ aluno_fullscreen: indice, alunoChat_select: { ...this.state.alunos_m[Object.values(this.state.sala_select.alunos)[indice].id] } });
                                            this.fullscreen_ref.current.srcObject = Object.values(this.state.sala_select.alunos)[indice].videoRef.current.srcObject;
                                        }
                                        else {
                                            let indice = this.state.aluno_fullscreen - 1;
                                            this.setState({ aluno_fullscreen: indice, alunoChat_select: { ...this.state.alunos_m[Object.values(this.state.sala_select.alunos)[indice].id] } });
                                            this.fullscreen_ref.current.srcObject = Object.values(this.state.sala_select.alunos)[indice].videoRef.current.srcObject;
                                        }
                                    }} style={{ position: 'absolute', top: '50%', left: '10px', color: 'white', zIndex: 5000 }} className="close"><i className="mdi mdi-arrow-left-thick" style={{ fontSize: '1.7rem' }}></i></div>

                                    <div onClick={() => {
                                        if (this.state.aluno_fullscreen == Object.values(this.state.sala_select.alunos).length - 1) {
                                            let indice = 0;
                                            this.setState({ aluno_fullscreen: indice, alunoChat_select: { ...this.state.alunos_m[Object.values(this.state.sala_select.alunos)[indice].id] } });
                                            this.fullscreen_ref.current.srcObject = Object.values(this.state.sala_select.alunos)[indice].videoRef.current.srcObject;
                                        }
                                        else {
                                            let indice = this.state.aluno_fullscreen + 1;
                                            this.setState({ aluno_fullscreen: indice, alunoChat_select: { ...this.state.alunos_m[Object.values(this.state.sala_select.alunos)[indice].id] } });
                                            this.fullscreen_ref.current.srcObject = Object.values(this.state.sala_select.alunos)[indice].videoRef.current.srcObject;
                                        }
                                    }} style={{ position: 'absolute', top: '50%', right: '10px', color: 'white', zIndex: 5000 }} className="close"><i className="mdi mdi-arrow-right-thick" style={{ fontSize: '1.7rem' }}></i></div> */}

                                    {/* <div style={{ position: 'absolute', color: 'white', background: 'black', right: 0, left: 0, bottom: 0 }}>
                                        {this.state.aluno_fullscreen != -1 && <p style={{ textAlign: 'center', margin: 0 }}>({this.state.aluno_fullscreen + 1}/{Object.keys(this.state.sala_select.alunos).length}) - {Object.values(this.state.sala_select.alunos)[this.state.aluno_fullscreen].name}</p>}
                                    </div> */}
                                    {this.state.alunoChat_select.mensagens != undefined && <div style={{ position: 'absolute', color: 'white', boxShadow: '0px 1px 3px 0px rgb(60 64 67 / 30%), 0px 4px 8px 3px rgb(60 64 67 / 15%)', borderRadius: 8, background: 'rgba(0,0,0,0.5)', right: 0, left: 0, bottom: 0, height: 'auto', padding: 5 }}>
                                        <p style={{ margin: 0 }}>{this.state.alunoChat_select.name}</p>
                                        <div style={{ display: 'flex',justifyContent:'space-between',flexWrap:'wrap' }}>
                                        <div style={{ display: 'flex', alignItems: 'center',flexWrap:'wrap'   }}>

                                            {this.props.processo.chat_type != 'none' && <div data-tip={"Abrir o chat com o candidato " + this.state.alunoChat_select.name + ""} className="icon_box" style={{ position: 'relative', marginRight: 2 }} onClick={(e) => {
                                                // e.stopPropagation();
                                                let aluno2 = this.state.alunos_m[this.state.alunoChat_select.id];
                                                this.sendReceive(aluno2);

                                                this.props.mudarChatOpen(0);
                                            }}>
                                                <ReactTooltip />

                                                <i style={{ color: 'inherit' }} className="mdi mdi-chat"></i>
                                                {this.state.alunos_m[this.state.alunoChat_select.id] != undefined && this.state.alunos_m[this.state.alunoChat_select.id].counter > 0 && <div style={{
                                                    // padding: '2px',
                                                    // paddingTop: '3px',
                                                    // height:15,
                                                    minWidth: 20,
                                                    background: 'rgb(75, 181, 67)',
                                                    borderRadius: '50px',
                                                    position: 'absolute',
                                                    top: -5,
                                                    textAlign: 'center',
                                                    // paddingBottom: '3px',
                                                    right: -5
                                                }}>

                                                    <span style={{ color: 'white', textAlign: 'center', fontSize: 11 }}>{this.state.alunos_m[this.state.alunoChat_select.id].counter}</span>
                                                </div>}
                                            </div>}

                                            {this.props.processo.chat_type != 'none' && <div data-tip={"Visualizar solicitações do candidato " + this.state.alunos_m[this.state.alunoChat_select.id].name + ""} className="icon_box" style={{ position: 'relative', marginRight: 2 }} onClick={(e) => {
                                                        // e.stopPropagation();
                                                        if (this.state.loading_sala === true) {
                                                            return;
                                                        }
                                                        let aluno2 = this.state.alunos_m[this.state.alunoChat_select.id];
                                                        this.setState({  alunoChat_select: { ...aluno2 } });

                                                        this.props.mudarChatOpen(1);
                                                    }}>
                                                        <ReactTooltip />

                                                        <i style={{ color: 'inherit' }} className="mdi mdi-pause-circle"></i>
                                                        {this.state.alunos_m[this.state.alunoChat_select.id] != undefined && (this.state.alunos_m[this.state.alunoChat_select.id].requests.length > 0 && 
                                                            this.state.alunos_m[this.state.alunoChat_select.id].requests[this.state.alunos_m[this.state.alunoChat_select.id].requests.length-1].approved==null
                                                        )  && <div style={{
                                                            // padding: '2px',
                                                            // paddingTop: '3px',
                                                            // height:15,
                                                            minWidth: 20,
                                                            background: '#17a2b8',
                                                            borderRadius: '50px',
                                                            position: 'absolute',
                                                            top: -5,
                                                            textAlign: 'center',
                                                            // paddingBottom: '3px',
                                                            right: -5
                                                        }}>

                                                            <span style={{ color: 'white', textAlign: 'center', fontSize: 11 }}>1</span>
                                                        </div>}
                                                    </div>}

                                            <div data-tip={"Número de vezes que o candidato " + this.state.alunoChat_select.name + " saiu da tela"} className="icon_box" style={{ position: 'relative', marginRight: 2 }} >
                                                <ReactTooltip />
                                                <span style={{ color: this.state.alunos_m[this.state.alunoChat_select.id].mensagens.filter((item, id) => item.warning == true && item.message == 'O candidato saiu da página da avaliação').length > 0 ? 'yellow' : 'white', textAlign: 'center', fontSize: 11 }}>{this.state.alunos_m[this.state.alunoChat_select.id].mensagens.filter((item, id) => item.warning == true && item.message == 'O candidato saiu da página da avaliação').length}</span>

                                            </div>
                                            {this.props.processo.screen_sharing == true && <div
                                                onClick={() => {
                                                    // this.setState({ full_screen_open: true, aluno_fullscreen: id, alunoChat_select: { ...this.state.alunos_m[aluno.id] } });
                                                    // this.fullscreen_ref.current.srcObject = aluno.videoRef.current.srcObject;
                                                    if (this.state.loading_alter_video[this.state.alunoChat_select.id] != true) {
                                                        let loading_alter_video = { ...this.state.loading_alter_video }
                                                        loading_alter_video[this.state.alunoChat_select.id] = true;
                                                        this.setState({ loading_alter_video })
                                                        this.socket.emit("alter_video", { aluno_id: this.state.alunoChat_select.id, faculdade_id: this.props.faculdade.id, admin_id: this.admin_id });
                                                        setTimeout(() => {
                                                            let loading_alter_video = { ...this.state.loading_alter_video }
                                                            loading_alter_video[this.state.alunoChat_select.id] = false;;
                                                            this.setState({ loading_alter_video })
                                                        }, 5000)
                                                    }
                                                }}
                                                data-tip={"Alterar o vídeo do candidato para a webcam ou tela compartilhada"} className="icon_box" style={{ position: 'relative', marginRight: 2 }} >
                                                <ReactTooltip />
                                                {this.state.loading_alter_video[this.state.alunoChat_select.id] != true && <i style={{ color: 'inherit' }} className="mdi mdi-monitor-multiple"></i>}
                                                {this.state.loading_alter_video[this.state.alunoChat_select.id] == true && <div className="spinner-border text-primary" role="status" style={{ width: '1rem', height: '1rem', margin: 0 }} >
                                                </div>}

                                            </div>}
                                            <div
                                                onClick={() => {
                                                    this.setState({ candidato_selectAcao: this.state.alunoChat_select, show_modal_action: true });
                                                    // this.setState({ full_screen_open: true, aluno_fullscreen: id, alunoChat_select: { ...this.state.alunos_m[aluno.id] } });
                                                    // this.fullscreen_ref.current.srcObject = aluno.videoRef.current.srcObject;
                                                }}
                                                data-tip={"Reportar ação do candidato"} className="icon_box" style={{ position: 'relative', marginRight: 2 }} >
                                                <ReactTooltip />
                                                <i style={{ color: 'inherit' }} className="fas fa-exclamation-circle"></i>

                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: 2 }}>
                                                {this.state.alunos_m[this.state.alunoChat_select.id].finish == 'F' && <div data-tip={"Candidato finalizou a avaliação"} style={{ borderRadius: 100, backgroundColor: '#815ae4', height: 25, display: 'flex', justifyContent: 'center', alignItems: 'center', width: 30, height: 30 }}>
                                                    <p style={{ margin: 0, color: 'white', fontSize: 10 }}>F</p>
                                                    <ReactTooltip />
                                                </div>}
                                                {this.state.alunos_m[this.state.alunoChat_select.id].finish == 'I' && <div data-tip={"Candidato não finalizou a avaliação"} style={{ borderRadius: 100, backgroundColor: 'darkgoldenrod', height: 25, display: 'flex', justifyContent: 'center', alignItems: 'center', width: 30, height: 30 }}>
                                                    <p style={{ margin: 0, color: 'white', fontSize: 10 }}>NF</p>
                                                    <ReactTooltip />
                                                </div>}
                                            </div>
                                            {this.props.processo.good_quality && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <div onClick={() => {
                                                    if(this.good_quality_student[this.state.alunoChat_select.id]!=true && this.props.processo.good_quality==true){
                                                        this.good_quality_student[this.state.alunoChat_select.id]=true;
                                                        console.log(this.good_quality_student)
                                                        this.socket.emit("good_quality", {good:true, aluno_id: this.state.alunoChat_select.id, faculdade_id: this.props.faculdade.id, admin_id: this.admin_id });
                                                    }
                                                }} data-tip={"Video em boa qualidade"} className="icon_box" style={{ position: 'relative' }}>
                                                    <i style={{ color: 'inherit' }} className="mdi mdi-high-definition"></i>
                                                    <ReactTooltip />
                                                </div>
                                            </div>}

                                            

                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <div onClick={() => {
                                                    if (this.state.times_reconects[this.state.alunoChat_select.id] == null) {
                                                        let times_reconects = { ...this.state.times_reconects };

                                                        times_reconects[this.state.alunoChat_select.id] = moment();
                                                        this.setState({ times_reconects });
                                                        console.log('Reconectando');
                                                        this.socket.emit("reconect_user", { aluno_id: this.state.alunoChat_select.id, faculdade_id: this.props.faculdade.id, admin_id: this.admin_id });

                                                    }
                                                    else {
                                                        if (moment().diff(this.state.times_reconects[this.state.alunoChat_select.id], 'seconds') > 60) {
                                                            let times_reconects = { ...this.state.times_reconects };

                                                            times_reconects[this.state.alunoChat_select.id] = moment();
                                                            this.setState({ times_reconects });
                                                            this.socket.emit("reconect_user", { aluno_id: this.state.alunoChat_select.id, faculdade_id: this.props.faculdade.id, admin_id: this.admin_id });
                                                        }
                                                        else {
                                                            console.log(moment().diff(this.state.times_reconects[this.state.alunoChat_select.id], 'seconds'))
                                                        }

                                                    }
                                                }} data-tip={"Reconectar com o candidato"} className="icon_box" style={{ position: 'relative' }}>
                                                    <i style={{ color: 'inherit' }} className="mdi mdi-reload"></i>
                                                    <ReactTooltip />
                                                </div>
                                            </div>
                                            {this.state.alunos_m[this.state.alunoChat_select?.id]?.presence!=null && <div
                                                        onClick={() => {
                                                            this.setState({list_open:!this.state.list_open})
                                                        }}
                                                        data-tip={this.state.alunos_m[this.state.alunoChat_select.id].presence.presence? "Candidato marcado como presente":"Candidato marcado como ausente"} className="icon_box" style={{ position: 'relative', marginRight: 2,background:this.state.alunos_m[this.state.alunoChat_select.id].presence.presence?'green':'red' }} >
                                                        <ReactTooltip />
                                                        <i style={{ color: 'inherit' }} className={this.state.alunos_m[this.state.alunoChat_select?.id].presence.presence?"mdi mdi-check": "mdi mdi-close"}></i>

                                                    </div>}
                                            </div>
                                            {this.state.alunoChat_select?.accessibility==true && this.state.alunoChat_select?.type_supervisor!='none' && <div style={{ justifyContent:'center',alignItems:'center', display: 'flex' }}>

                                                {this.props.socket != null && <button onClick={() => {
                                                    if(this.good_quality_student[this.state.alunoChat_select.id] && this.props.processo.good_quality==true){
                                                        this.good_quality_student[this.state.alunoChat_select.id]=false;
                                                        this.socket.emit("good_quality", {good:false, aluno_id: this.state.alunoChat_select.id, faculdade_id: this.props.faculdade.id, admin_id: this.admin_id });
                                                    }
                                                    this.fullscreen_ref.current.srcObject = null;
                                                    this.setState({full_screen_open:false,aluno_fullscreen: -1,})
                                                    let keys = Object.keys(this.list_peers);
                                                    for (let i = 0; i < keys.length; i++) {
                                                        this.list_peers[keys[i]].close();
                                                    }
                                                    for (let i = 0; i < Object.keys(this.state.sala_select.alunos).length; i++) {
                                                        if (this.state.sala_select.alunos[Object.keys(this.state.sala_select.alunos)[i]].videoRef.current != null) {
                                                            this.state.sala_select.alunos[Object.keys(this.state.sala_select.alunos)[i]].desconectado = true;
                                                            this.state.sala_select.alunos[Object.keys(this.state.sala_select.alunos)[i]].videoRef.current.srcObject = null;
                                                        }

                                                    }
                                                    this.socket.emit('change_room', { name: 'Admin', sala_id: null, last_sala:this.state.sala_select.id, faculdade_id: this.props.faculdade.id, admin_id: this.admin_id });

                                                    this.props.changeShowCall(true)
                                                    // this.finishExam();
                                                }} type="button" id="finalizar" className="btn btn-outline-info btn-lg waves-effect waves-light"
                                                >Entrar em chamada<i className='mdi mdi-call-made'></i></button>}

                                            </div>}




                                        </div>
                                    </div>}
                                </div>

                                <div className="row" style={{ margin: 0 }}>
                                    {/* {this.props.processo.type_fiscalization == 'image' && <div className="col-12 ">
                                        {this.props.socket != null && <div style={{ zIndex: 3000, width: '100%' }}> <Chat closeChat={() => {
                                            this.setState({ show_modal_chat_geral: false });
                                        }} alunoChat_select={this.state.alunoChat_select} show_modal_chat_geral={this.state.show_modal_chat_geral} online={this.state.number_online} alunos={this.state.alunos_m} admin_id={this.admin_id} comunicados={this.state.comunicados} mudarState={(data) => {
                                            let state = {}
                                            if (data.alunos != undefined) state.alunos_m = data.alunos;
                                            if (data.comunicados != undefined) state.comunicados = data.comunicados;

                                            this.setState(state);

                                        }} />
                                            <br />
                                            <br />

                                        </div>}
                                    </div>} */}
<div className={this.state.list_open != false ? 'chat_individual_open' : 'chat_individual_close'} style={{ position: 'fixed', zIndex: 1000, maxWidth: 500, top: 0, bottom: 0, width: 300 }}>
                                    <ListStudents 
                                        full_screen_open={this.state.full_screen_open}
                                        aluno_fullscreen={this.state.aluno_fullscreen}
                                        list_open={this.state.list_open}
                                        goFullScreen={(aluno)=>{
                                            this.setState({ full_screen_open: true, aluno_fullscreen: aluno.id, alunoChat_select: { ...this.state.alunos_m[aluno.id] } });
                                            this.fullscreen_ref.current.srcObject = this.state.sala_select.alunos[aluno.id].videoRef.current.srcObject;
                                        }}
                                        setListOpen={(e)=>{
                                            this.setState({list_open:e})
                                        }}
                                        alunos_m={this.state.alunos_m}
                                        number_online={this.state.number_online}
                                        mudarState={(data) => {
                                            let state = {}
                                            if (data.alunos_m != undefined) state.alunos_m = data.alunos_m;

                                            this.setState(state);

                                        }}
                                    />
                                    </div>

                                    {(this.props.processo.type_fiscalization == 'all' || this.props.processo.type_fiscalization == 'image') && <div className="col-12 " hidden={this.state.show_modal_chat_geral == false} style={{
                                        position: 'fixed',
                                        right: 0,
                                        bottom: 0,
                                        left: 0,
                                        top: 0,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        // background: 'rgba(0,0,0,0.4)',
                                        zIndex: 2000
                                    }}>


                                        {this.props.socket != null && <div style={{ zIndex: 3000, width: '100%' }}> <Chat closeChat={() => {
                                            this.setState({ show_modal_chat_geral: false });
                                        }} 
                                        show_chat_meet={this.state.show_chat_meet}
                                        clearTimeout={(id)=>{
                                            let loadings_pause={...this.props.loadings_pause}
                                            loadings_pause[id]=false;
                                            clearTimeout(this.timeout_request[id])
                                            let additional={}
                                            if(this.state.requestSelect?.id==id){
                                                additional.show_modal_request=false;
                                            }
                                            this.setState({loadings_pause,...additional})
                                        }}
                                        video_blink={this.state.video_blink}  video_blink_movement={this.state.video_blink_movement} alunoChat_select={this.state.alunoChat_select} show_modal_chat_geral={this.state.show_modal_chat_geral} online={this.state.number_online} alunos={this.state.alunos_m} admin_id={this.admin_id} comunicados={this.state.comunicados} mudarState={(data) => {
                                            let state = {}
                                            if (data.alunos != undefined) state.alunos_m = data.alunos;
                                            if (data.comunicados != undefined) state.comunicados = data.comunicados;
                                            if (data.video_blink != undefined) state.video_blink = data.video_blink;
                                            if (data.video_blink_movement != undefined) state.video_blink_movement = data.video_blink_movement;


                                            this.setState(state);

                                        }} /></div>}

                                        <div onClick={() => {
                                            this.setState({ show_modal_chat_geral: false });
                                        }}
                                            style={{
                                                position: 'absolute',
                                                right: 0,
                                                bottom: 0,
                                                left: 0,
                                                top: 0,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                background: 'rgba(0,0,0,0.4)',
                                                zIndex: 2000
                                            }}
                                        >
                                        </div>

                                    </div>}
                                    {this.props.socket != null && this.props.processo.chat_type != 'none' && <div className={this.props.chat_open != -1 ? 'chat_individual_open' : 'chat_individual_close'} style={{ position: 'fixed', zIndex: 1000, maxWidth: 400, top: 0, bottom: 0, width: 300 }}>
                                        <ChatIndividual 
                                            send_message={(m) => this.send_message(m)}
                                        loadings_pause={this.state.loadings_pause}
                                        sendReceive={()=>{
                                            this.sendReceive(this.state.alunoChat_select);
                                        }}
                                        chat_open={this.props.chat_open} aluno_select={this.state.alunoChat_select} online={this.state.number_online} alunos={this.state.alunos_m} admin_id={this.admin_id} comunicados={this.state.comunicados} mudarState={(data) => {
                                            let state = {}
                                            if (data.alunos != undefined) state.alunos_m = data.alunos;
                                            if (data.comunicados != undefined) state.comunicados = data.comunicados;
                                            if (data.request != undefined) state.requestSelect = data.request;
                                            if (data.request != undefined) state.show_modal_request = data.show_modal_request;



                                            this.setState(state);

                                        }} />
                                    </div>}

                                    <div className="col-12 col-md-6 d-flex justify-content-center">
                                        <ReportAction setState={(e) => {
                                            this.setState(e);
                                        }} show_modal_action={this.state.show_modal_action} candidatos={this.state.candidatos} sala={this.state.sala_select} candidato_select={this.state.candidato_selectAcao} />
                                    </div>
                                    <SolicitacaoModal
                                        loadings_pause={this.state.loadings_pause}

                                    sendRequestResponse={(data)=>{
                                        let loadings_pause={...this.props.loadings_pause}
                                        loadings_pause[data.id]=true;
                                        this.setState({loadings_pause})
                                        console.log('request dada',data)
                                        this.props.socket.emit('request_pause_response',{...data});
                                        this.timeout_request[data.id]=setTimeout(()=>{
                                            let loadings_pause={...this.props.loadings_pause}
                                        loadings_pause[data.id]=false;
                                            this.setState({loadings_pause,message_warning:'Não foi possível responder a solicitação do candidato '+data.name+' da Sala '+data.sala_numero});
                                        },10000);
                                    }}
                                    request={this.state.requestSelect} setState={(e) => {
                                            this.setState(e);
                                        }}  show_modal_request={this.state.show_modal_request} sala={this.state.sala_select} candidato_select={this.state.alunoChat_select}  />
                                </div>
                            </div>
                        </div>
                    </div>
                    <SweetAlert
                    warning
                    title={'Aviso!'}
                    onConfirm={() => this.setState({ message_warning: null })}
                    show={this.state.message_warning!=null}
                    confirmBtnText='Entendi'
                    confirmBtnBsStyle="danger"
                >
                    {this.state.message_warning}
                </SweetAlert>

                {this.props.show_call && <MeetingRoom
                    changeStudent={(candidato)=>{
                        let candidatos = {...this.state.alunos_m}
                        candidatos[candidato.id] = candidato;
                       
                        this.setState({alunos_m:candidatos});
                    }}
                    entrevistas={[]}
                        changeShowChatMeet={(e) => {
                            this.setState({ show_chat_meet: e });
                            if (e == true) {
                                let aluno = this.state.alunoChat_select;
                                if (aluno != undefined) {
                                    this.sendReceive(aluno);
                                }
                            }
                        }}
                        send_message={(m) => this.send_message(m)}
                        getAdminId={() => this.admin_id} processo={this.props.processo} number_online={this.state.number_online} closeCall={() => {
                            this.props.changeShowCall(false);
                            this.socket.emit('change_room', { name: 'Admin', sala_id: this.state.sala_select.id, last_sala:this.state.sala_select.id, faculdade_id: this.props.faculdade.id, admin_id: this.admin_id });

                            this.setState({ alunoChat_select: {} })
                        }}
                        students={Object.values(this.state.alunos_m)}
                        get_messages_from_student={(id) => this.get_messages_from_student(this.state.alunoChat_select.id)}
                        id_candidato={this.state.alunoChat_select?.id}
                        student={this.state.alunoChat_select} />}

                </div>}
                {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    <div className="spinner-border text-primary" role="status"  >
                        <span className="sr-only">Loading...</span>
                    </div>
                </div></div>}
            </div>


        );
    }


}

const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        faculdade: state.AppReducer.faculdade,
        processo: state.AppReducer.processo,
        user: state.AppReducer.user,
        socket: state.AppReducer.socket,
        chat_open: state.AppReducer.chat_open,
        show_call: state.AppReducer.show_call,
    }
);

export default connect(mapsStateToProps, { changeConnect, mudarUser, logout, mudarProcesso, mudarSocket, mudarSalaScreen, mudarChatOpen,changeShowCall })(Sala);
